export interface UserProfile {
	email?: string;
	password?: string;
	currentTime?: string;
	locale?: string;
	profile?: {
		firstName: string;
		lastName: string;
		birthDate: string;
		gender: string;
		country: string;
	};
	favoriteTeams?: string[];
	newsletter?: {
		bundesligaNewsletter?: boolean;
		partnerNewsletter?: boolean;
		fantasyNewsletter?: boolean;
		subscriptionSource?: string;
	};
}

export interface UserProfileRegistration extends UserProfile {
	locale?: string;
	product: string;
	preferences?: {
		tos: boolean;
	};
	data?: {
		registerSource: string;
		registerType: string;
		enviroment: string;
	};
	custom?: {
		[key: string]: string;
	};
}

export interface OktaActivation {
	'expiresAt': string;
	// eslint-disable-next-line
	'status': 'SUCCESS' | 'UNAUTHENTICATED' | 'PASSWORD_RESET' | 'PASSWORD_WARN' | 'LOCKED_OUT' | 'PASSWORD_EXPIRED' | 'RECOVERY';
	'sessionToken'?: string; // @fixme
	'stateToken'?: string; // @fixme
	'_embedded': {
		'user': {
			'id': string;
			'passwordChanged': string;
			'profile': {
				'login': string;
				'firstName': string;
				'lastName': string;
				'locale': string;
				'timeZone': string;
			};
		};
	};
}

export interface UserNewsletters {
	bundesligaNewsletter?: boolean;
	partnerNewsletter?: boolean;
	fantasyNewsletter?: boolean;
	predictorNewsletter?: boolean;
	predictor2BLNewsletter?: boolean;
	subscriptionSource?: string;
}
export enum UserSubscriptionSource {
	BL_APP_OKTA_PROFILE = 'bl.app_account_profile',
	BL_APP_OKTA_SIGNUP = 'bl.app_account_signup',
	BL_NEWSLETTER_SIGNUP_BOX = 'bl.com_newsletter_only',
	BL_OKTA_PROFILE = 'bl.com_account_profile',
	BL_OKTA_SIGNUP = 'bl.com_account_signup',
	BL_BARFINDER = 'bl.com_barfinder',
	FANTASY_WEB_OKTA_PROFILE = 'fantasy.web_account_profile',
	FANTASY_WEB_OKTA_SIGNUP = 'fantasy.web_account_signup',
	FANTASY_APP_OKTA_PROFILE = 'fantasy.app_account_profile',
	FANTASY_APP_OKTA_SIGNUP = 'fantasy.app_account_signup'
}
